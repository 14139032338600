<template>
  <ValidationObserver @submit="formCheck">
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="modal"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ modalHeading }}
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiInput
            v-model="lesson.title"
            type="text"
            name="Lesson Name"
            title="Lesson Name"
            label="Lesson Name"
            placeholder="Lesson Name"
            class="flex-1"
            rules="required"
          />

          <UiSingleSelect
            v-model="lesson.type"
            title="Lesson Type"
            :options="types"
            label="title"
            class="flex-1"
            rules="required"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="selectedSubject"
            title="SUBJECT"
            :options="subjectList"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
            :search-through-api="true"
            @change="subjectChapters(selectedSubject)"
            @search="getSubjects"
          />
          <UiSingleSelect
            v-model="lesson.chapter_id"
            title="Chapter"
            :options="chapterList"
            :search-through-api="true"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
            @search="subjectChapters(selectedSubject, $event)"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="lesson.topic_id"
            title="TOPIC"
            :options="topicsList"
            :search-through-api="true"
            label="title"
            class="flex-1"
            reduce="id"
            rules="required"
            @search="_getTopics"
          />

          <UiInput
            v-model="lesson.lesson_number"
            type="text"
            name="Lesson Number"
            title="Lesson Number"
            label="Lesson Number"
            placeholder="Lesson Number"
            rules="required|numeric"
            class="flex-1"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiInput
            v-model="lesson.description"
            type="textarea"
            name="Description"
            title="Description"
            rows="4"
            :placeholder="$t(`placeholder.write`)"
            resize="false"
          ></UiInput>
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              {{ saveBtnTitle }}
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import Loader from '@components/BaseComponent/Loader.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapState, mapActions } from 'vuex'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import GENERAL_CONSTANTS from '@/src/constants/general-constants'
import { removeEmptyKeysFromObject } from '@utils/generalUtil'
import { buildWhereQuery } from '@utils/filters/index'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'LessonModal',
  components: {
    ValidationObserver,
    UiModalContainer,
    UiInput,
    UiSingleSelect,
    Loader,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    edit: { type: Object, default: () => {} },
    modal: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  data() {
    return {
      lesson: {
        title: '',
        description: '',
        type: 'Theory',
        lesson_number: null,
        chapter_id: '',
        topic_id: '',
      },
      dashboard: 'dashboard',
      placeholder: 'placeholder',
      title: 'title',
      isLoading: false,
      chapterId: '',
      statuses: ['Complete', 'P.Complete', 'Missed', 'Incomplete'],
      types: ['Theory', 'Lab', 'Assignment'],
      chapterList: [],
      subjectList: [],
      topicsList: [],
      selectedSubject: '',
    }
  },
  computed: {
    ...mapState({
      classId: (state) => state.layout.currentSectionScope.id,
    }),
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Create'
    },
    modalHeading() {
      return this.edit ? `Edit Lesson` : 'Add Lesson'
    },
  },
  watch: {
    'lesson.chapter_id': {
      handler() {
        this._getTopics()
      },
    },
  },
  mounted() {
    this.getSubjects()
    if (this.classId) this.lesson.section_id = this.classId
  },
  methods: {
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('chapter', ['getChapters', 'getTopics']),
    ...mapActions('lesson', ['addLesson']),
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          break
        case 'save':
          break
      }
    },
    setTopicData() {
      this.lesson = {
        chapter_id: this.edit?.topic.chapter?.id,
        topic_id: this.edit?.topic?.id,
        title: this.edit?.title,
        description: this.edit?.description,
        lesson_number: this.edit?.lesson_number,
        status: '',
        type: this.edit?.type,
      }
    },
    formCheck() {
      try {
        this.isLoading = true
        const data = {
          lesson: this.lesson,
        }
        if (this.edit) data.id = this.edit?.id
        this.addLesson(data)
          .then((res) => {
            if (this.edit)
              this.$store.commit(
                'toast/NEW',
                { type: 'success', message: this.$t(`toast.Successfully Updated`) },
                { root: true },
              )
            else
              this.$store.commit(
                'toast/NEW',
                { type: 'success', message: this.$t(`toast.Successfully Created`) },
                { root: true },
              )
            this.$emit('toggle', true)
          })
          .finally(() => {
            this.isLoading = false
          })
      } catch (err) {}
    },
    _getTopics(query) {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', this.selectedSubject),
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'chapter_id', this.lesson.chapter_id),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      removeEmptyKeysFromObject(payload)
      this.getTopics(payload).then((res) => {
        this.topicsList = res.records
      })
    },
    async getSubjects(query) {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.classSubjects(payload).then((res) => {
        this.subjectList = res.data.records
        if (this.edit) {
          this.selectedSubject = this.edit?.topic.chapter?.subject.id
          this.subjectChapters(this.selectedSubject).then((res) => {
            this.setTopicData()
          })
          let isExist = this.subjectList.some((el) => el.id === this.selectedSubject)
          if (!isExist) this.subjectList.unshift(this.edit.topic.chapter.subject)
        }
      })
    },
    async subjectChapters(subject, query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_id', subject),
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      removeEmptyKeysFromObject(payload)

      this.getChapters(payload).then((res) => {
        this.chapterList = res.records
      })
    },
  },
}
</script>
